import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  width: 75%;
  padding: 2em;
  z-index: 1;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  border-radius: 0.25rem;
  background-color: ${props => props.backgroundColor || '#F7F7F7'};
`

function ContentWithBackground({ data }) {
  const { header, backgroundColor } = data

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Content>
        <h2>{header}</h2>
      </Content>
      <Background />
    </Wrapper>
  )
}

ContentWithBackground.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment ContentWithBackgroundFragment on ContentfulContentWithBackground {
    header
    backgroundColor
  }
`

export default ContentWithBackground
