import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin-bottom: 8em;
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => `${props.position}: 0`};
  width: 40%;
  padding: 40px;
  ${props => `border-top-${props.position === 'right' ? 'left' : 'right'}-radius: .25rem`};
  ${props => `border-bottom-${props.position === 'right' ? 'left' : 'right'}-radius: .25rem`};
  background-color: white;
`

function HeroWithContent({ data }) {
  const { header, image, content, position } = data
  const paragraphs = (get(content, 'json.content') || [])
    .map(({ content }) => get(content, `[0].value`))

  return (
    <Wrapper>
      <Img
        style={{
          maxWidth: '100%',
          maxHeight: '600px',
          borderRadius: '.25rem',
        }}
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={image.fluid}
      />
      <Content position={position}>
        <h1>{header}</h1>
        {paragraphs.map((paragraph, key) => <p key={key}>{paragraph}</p>)}
      </Content>
    </Wrapper>
  )
}

HeroWithContent.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment HeroWithContentFragment on ContentfulHeroWithContent {
    header
    content {
      json
    }
    image {
      fluid {
        src
        srcSet
        sizes
        aspectRatio
      }
    }
    position
  }
`

export default HeroWithContent
