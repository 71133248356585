import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import ImageWithHeader from '../sections/ImageWithHeader'
import HeroWithContent from '../sections/HeroWithContent'
import ContentWithImage from '../sections/ContentWithImage'
import ContentWithBackground from '../sections/ContentWithBackground'

const Wrapper = styled.section`
  position: relative;
  width: 100%;
`

const getComponentByType = type => {
  switch (type) {
    case 'ContentfulImageWithHeader':
      return ImageWithHeader
    case 'ContentfulHeroWithContent':
      return HeroWithContent
    case 'ContentfulContentWithImage':
      return ContentWithImage
    case 'ContentfulContentWithBackground':
      return ContentWithBackground
    default:
      return null
  }
}

const Section = ({ data }) => {
  const sectionType = data.__typename
  const Component = getComponentByType(sectionType)

  return !!Component && (
    <Wrapper>
      <Component data={data} />
    </Wrapper>
  )
}

Section.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment SectionFragment on Node {
    __typename
    ... on ContentfulImageWithHeader {
      ...ImageWithHeaderFragment
    }
    ... on ContentfulHeroWithContent {
      ...HeroWithContentFragment
    }
    ... on ContentfulContentWithImage {
      ...ContentWithImageFragment
    }
    ... on ContentfulContentWithBackground {
      ...ContentWithBackgroundFragment
    }
  }
`

export default Section
