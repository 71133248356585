import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SEO from './SEO'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`

const Layout = ({ title, slug, pages, children }) => (
  <Fragment>
    <SEO {...{ title, slug }} />
    <Wrapper>
      <Header {...{ title, slug, pages }} />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  </Fragment>
)

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
