import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/shared/Layout'
import Section from '../components/shared/Section'

// Set the order of links in the header
// coresponse to the page's title in contentful
const pageList = ['Home', 'ADHD', 'Coaching']

const Page = ({ data, pageContext }) => {
  const { title, slug, sections = [] } = data.contentfulPage
  const pages = pageList
    .map(page => pageContext.pages.find(p => p.title === page))
    .filter(page => !!page)

  return (
    <Layout {...{ title, slug, pages }}>
      {sections.map((section, key) => (
        <Section key={key} data={section} />
      ))}
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const query = graphql`
  query($contentfulId: String!) {
    contentfulPage(contentful_id: { eq: $contentfulId }) {
      title
      slug
      sections {
        ...SectionFragment
      }
    }
  }
`

export default Page
