import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 15vh;
  padding: 40px 0;
  color: white;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Line = styled.div`
  line-height: 1.5;
`

function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      logo: file(relativePath: { eq: "initial.png" }) {
        childImageSharp {
          fixed(height: 39) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Section>
        <Img fixed={data.logo.childImageSharp.fixed} />
      </Section>
      <Section>
        <Line>Locations in New York & New Jersey</Line>
        <Line>
          creADDiveSolutions.com | coaches@creaDDiveSolutions.com | (201)
          240-0921
        </Line>
      </Section>
      <Section>
        <Line>Terms & Conditions</Line>
        <Line>Copyright ©{new Date().getFullYear()} creADDive Solutions</Line>
      </Section>
    </Wrapper>
  )
}

export default Footer
