import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 15vh;
  background-color: white;
`

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

const Main = ({ children }) => (
  <Wrapper>
    <Container>{children}</Container>
  </Wrapper>
)

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
