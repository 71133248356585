import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ title, slug }) {
  const data = useStaticQuery(graphql`
    query SEOQuery {
      site {
        siteMetadata {
          titleTemplate
          description
          image
          url
        }
      }
    }
  `)
  const { siteMetadata } = data.site
  const seo = {
    title,
    slug,
    titleTemplate: siteMetadata.titleTemplate,
    description: siteMetadata.description,
    image: `${siteMetadata.url}${siteMetadata.image}`,
    url: `${siteMetadata.url}${slug}`,
  }
  return (
    <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
      <html lang="en" />
      <link rel="canonical" href={seo.url} />
      <link rel="preconnect" href="https://marketingplatform.google.com" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <meta name="keywords" content="ADHD, coaching, workshop" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export default SEO
