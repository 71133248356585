import React from 'react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin-bottom: 2em;
`

const Box = styled.div`
  position: relative;
  ${props => `${props.position}: 0`};
  top: 0;
  width: 75%;
  height: 460px;
  ${props => `margin-${props.rPosition}: 25%`};
  border-radius: 0.25rem;
  background-color: #F7F7F7;
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
  ${props => `margin-${props.rPosition}: 40%`};
  padding: 2em 4em;
`

function ContentWithImage({ data }) {
  const { header, image, content, position } = data
  const rPosition = position === 'left' ? 'right' : 'left'
  const paragraphs = (get(content, 'json.content') || [])
    .map(({ content }) => get(content, `[0].value`))

  return (
    <Wrapper>
      <Box position={position} rPosition={rPosition}>
        <Content rPosition={rPosition}>
          <h1>{header}</h1>
          {paragraphs.map((paragraph, key) => <p key={key}>{paragraph}</p>)}
        </Content>
      </Box>
      <Img
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          [rPosition]: '0',
          width: '50%',
          height: '380px',
          borderRadius: '.25rem',
        }}
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={image.fluid}
      />
    </Wrapper>
  )
}

ContentWithImage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment ContentWithImageFragment on ContentfulContentWithImage {
    title
    header
    content {
      json
    }
    position
    image {
      fluid {
        src
        srcSet
        sizes
        aspectRatio
      }
    }
  }
`

export default ContentWithImage
