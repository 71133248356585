import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin-bottom: 4em;
`

const Content = styled.div`
  position: relative;
  width: 60%;
  margin: -10em auto 0;
  max-height: 500px;
  padding: 60px;
  border-radius: .25rem;
  background-color: white;
`

function ImageWithHeader({ data }) {
  const { header, image, content, position } = data
  const paragraphs = (get(content, 'json.content') || [])
    .map(({ content }) => get(content, `[0].value`))

  return (
    <Wrapper>
      <Img
        style={{
          width: '100%',
          maxHeight: '400px',
          borderRadius: '.25rem',
        }}
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={image.fluid}
      />
      <Content position={position}>
        <h1>{header}</h1>
        {paragraphs.map((paragraph, key) => <p key={key}>{paragraph}</p>)}
      </Content>
    </Wrapper>
  )
}

ImageWithHeader.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment ImageWithHeaderFragment on ContentfulImageWithHeader {
    header
    content {
      json
    }
    image {
      fluid {
        src
        srcSet
        sizes
        aspectRatio
      }
    }
    position
  }
`

export default ImageWithHeader